<template>
  <div class="page_img4">
    <div class="page_title">
      <div class="title">便捷流程&nbsp;&nbsp;省时省心</div>
      <div class="hint">
        <p>您只需要上传食品标签基本信息，剩余的工作就交给我们</p>
        <p>我们将为您把关标签设计、评估违规风险、提供合理化建议</p>
      </div>
    </div>
    <div class="page_info">
      <el-row type="flex" align="middle" justify="space-between">
        <el-col :span="5">
          <div class="box_list">
            <!-- <div class="box_li dib" @click="login_btn('flowpath')"> -->
            <div class="box_li dib" @click="$emit('btn','flowpath')">
              <router-link to>
                <div class="num">
                  <span class="iconfont iconxulie dib"></span>
                  <span class="text dib margin-left-m">注册账号</span>
                </div>
                <div class="icon">
                  <span class="iconfont iconzhuce"></span>
                </div>
                <div class="hint">
                  <p>验证手机</p>
                  <p>微信登录</p>
                </div>
              </router-link>
            </div>
          </div>
        </el-col>
        <el-col :span="1">
          <span class="icon_right iconfont iconjiantou"></span>
        </el-col>
        <el-col :span="6">
          <div class="box_list">
            <div class="box_li dib">
              <div class="num">
                <span class="iconfont iconxulie3 dib"></span>
                <span class="text dib margin-left-m">提交食品标签</span>
              </div>
              <div class="icon">
                <span class="iconfont icontijiao"></span>
              </div>
              <div class="hint">
                <p>提交标签信息</p>
                <p>支付服务费用</p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="1">
          <span class="icon_right iconfont iconjiantou"></span>
        </el-col>
        <el-col :span="5">
          <div class="box_list">
            <div class="box_li dib">
              <div class="num">
                <span class="iconfont iconxulie2 dib"></span>
                <span class="text dib margin-left-m">接收审核结果</span>
              </div>
              <div class="icon">
                <span class="iconfont iconjieshou"></span>
              </div>
              <div class="hint">
                <p>接收审核结果</p>
                <p>下载审核报告</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$yellow: rgb(242, 200, 17);
.page_img4 {
  background: $yellow;
  width: 100%;
  height: auto;

  .page_title {
    margin: auto;
    text-align: center;
    padding-top: 1.15rem;

    .title {
      font-size: 36px;
      font-weight: bold;
    }

    .hint {
      font-size: 20px;
    }
  }

  .page_info {
    margin: auto;
    padding: 1.45rem 5%;

    .box_list {
      vertical-align: middle;
      display: -webkit-box;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
    }

    .box_li {
      text-align: center;

      .num {
        .iconfont {
          font-size: 50px;
          vertical-align: middle;
        }

        .text {
          font-weight: bold;
          font-size: 26px;
          line-height: 50px;
          vertical-align: middle;
        }
      }

      .icon {
        .iconfont {
          font-size: 102px;
        }
      }

      .hint {
        font-size: 20px;
      }
    }

    .icon_right {
      font-size: 64px;
      vertical-align: middle;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .page_title {
      padding: 0 15px;
      padding-top: 15px;

      .title {
        font-size: 20px;
      }

      .hint {
        font-size: 13px;
      }
    }

    .page_info {
      width: 100%;
      padding: 30px 0;

      .box_li {
        .num {
          .iconfont {
            font-size: 15px;
          }

          .text {
            line-height: 1.5;
            font-size: 12px;
          }
        }

        .icon {
          .iconfont {
            font-size: 30px;
          }
        }

        .hint {
          font-size: 12px;
        }
      }

      .icon_right {
        font-size: 14px;
      }
    }
  }
}
</style>