<template>
  <div class="menu-list-wrap">
    <el-menu
      mode="horizontal"
      class="menu-more"
      background-color="#000"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-submenu index="2">
        <template slot="title">
          <span class="subtitle">文档</span>
        </template>
        <el-menu-item index="2-1">
          <a
            class="text-white"
            href="https://info.shihaishibiao.com/doc/5e5276fc30ab2d843859555f"
            target="_blank"
            >标签法规</a
          >
        </el-menu-item>
        <el-menu-item index="2-2">
          <a
            class="text-white"
            href="https://info.shihaishibiao.com/doc/5e529f1d5c8e9e54a49d91b7"
            target="_blank"
            >公告复函</a
          >
        </el-menu-item>
        <el-menu-item index="2-3">
          <a
            class="text-white"
            href="https://info.shihaishibiao.com/doc/5e537ca1b92be95ff484d81a"
            target="_blank"
            >相关标准</a
          >
        </el-menu-item>
        <el-menu-item index="2-4">
          <a
            class="text-white"
            href="https://info.shihaishibiao.com/qa/all"
            target="_blank"
            >常见问题</a
          >
        </el-menu-item>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <span class="subtitle">工具</span>
        </template>
        <el-menu-item index="5-1">
          <a
            class="text-white"
            href="https://info.shihaishibiao.com/tool/nrv"
            target="_blank"
            >营养素参考值（NRV）计算</a
          >
        </el-menu-item>
        <el-menu-item index="5-1">
          <a
            class="text-white"
            href="https://info.shihaishibiao.com/tool/energy"
            target="_blank"
            >能量值计算</a
          >
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        index="7"
        class="more-li"
        @click="openUrl('https://info.shihaishibiao.com/lesson')"
      >
        <a class="subtitle">课堂</a>
      </el-menu-item>
      <el-menu-item
        index="99"
        @click="
          openUrl(
            'https://www.shihaishibiao.com/docs/price.html#%E4%BC%98%E6%83%A0%E6%94%BF%E7%AD%96'
          )
        "
      >
        <a class="subtitle">价格</a>
      </el-menu-item>
      <el-menu-item
        index="1"
        class="more-li"
        :disabled="$route.path === '/train'"
      >
        <a class="subtitle" href="/train">培训</a>
      </el-menu-item>
      <el-menu-item
        index="8"
        class="more-li"
        @click="openUrl('https://www.shihaishibiao.com/docs/contactus.html')"
      >
        <a class="subtitle">关于</a>
      </el-menu-item>
      <el-submenu index="6" class="more-list">
        <template slot="title">
          <span class="subtitle">更多</span>
        </template>
        <el-menu-item
          index="7-1"
          @click="openUrl('https://info.shihaishibiao.com/lesson')"
        >
          <a class="subtitle text-white">精品课堂</a>
        </el-menu-item>
        <el-menu-item index="1" :disabled="$route.path === '/train'">
          <a class="subtitle text-white" href="/train">培训服务</a>
        </el-menu-item>
        <el-menu-item
          index="6-5"
          @click="openUrl('https://www.shihaishibiao.com/docs/contactus.html')"
        >
          <a class="text-white">关于我们</a>
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="user-login">
      <el-menu
        mode="horizontal"
        background-color="#000"
        text-color="#f5d341"
        active-text-color="#f5d341"
        class="menu-box"
      >
        <el-menu-item index="1" @click="$emit('btn', 1)">
          <span class="text-white text">登录</span>
        </el-menu-item>
        <el-menu-item index="2">
          <el-button type="primary" size="small" @click="$emit('btn', 2)">
            <span class="text" style="color: #000">免费注册</span>
          </el-button>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="https://www.shihaishibiao.com/docs/" target="_blank">
            <i class="el-icon-question icon" />
            <span style="color: #fff" class="text">帮助</span>
          </a>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-list-wrap {
  display: flex;
  width: 100%;
  ::v-deep .el-menu-item.is-active {
    border: 0 !important;
  }
  ::v-deep .el-submenu__title {
    border: 0 !important;
  }
  .menu-more {
    margin-left: 50px;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0;
  }
  .el-menu-item.is-active:before {
    display: none;
  }
  .user-login {
    flex: 1;
    text-align: center;
    display: flex;
    .menu-box {
      margin-left: auto;
    }

    .el-menu-item {
      border: 0 !important;
    }
    .icon {
      margin: 0;
      padding: 0;
      color: #f2c811;
    }
  }
  .text {
    font-size: 16px;
  }
  .subtitle {
    font-size: 15px;
  }
  .more-list {
    display: block;
  }
  .more-li {
    display: none;
  }

  @media screen and (min-width: 1370px) {
    .more-list {
      display: none;
    }
    .more-li {
      display: block;
    }
  }
}
</style>