<template>
  <el-header class="header">
    <div class="header_wrap">
      <LogoTxt @btn="$emit('btn','logo')" />
      <div class="right-menu-list">
        <MenuList @btn="onMenu" />
      </div>
    </div>
  </el-header>
</template>

<script>
import LogoTxt from "@/components/LogoTxt";
import MenuList from "@/components/MenuList";
export default {
  components: { LogoTxt, MenuList },
  methods: {
    register_btn() {
      this.MtaH5.clickStat("login", { topreg: "true" });
      this.$emit("register");
    },
    onMenu(index) {
      if (![1, 2].includes(index)) return;
      if (this.$route.path === "/train")
        return this.$router.replace(index === 1 ? "/?status=1" : "/");
      index === 1 ? this.$emit("btn", "toplogin") : this.register_btn();
    }
  }
};
</script>

<style lang="scss" scoped>
$yellow: rgb(242, 200, 17);
$height: 68px !important;
.el-header {
  background: #000;
  height: 68px !important;
  line-height: 68px !important;
  font-size: 0;
  line-height: 1;
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  min-width: 1100px;
  // font-family: Arial;
  .header_wrap {
    display: flex;
    margin: 0 3%;
    min-width: 1080px;
    .right-menu-list {
      height: $height;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (min-width: 1350px) {
    .header_wrap {
      margin: 0 5%;
    }
  }
  @media screen and (min-width: 1650px) {
    .header_wrap {
      margin: 0 10%;
    }
  }
}
</style>>
 