<template>
  <div class="page_img3">
    <div class="box_list min_page">
      <div class="map_list">
        <div class="box_title">
          <h3 class="title">因为专注&nbsp;&nbsp;所以专业</h3>
          <div class="hint">
            <p>十几年的行业经验只为服务于食品企业</p>
            <p>食品标签规范化制作公益培训全国行正在进行中</p>
          </div>
        </div>
        <img src="https://img.shihaishibiao.com/sh/page3.jpg?imageslim" alt="食品标签规范化制作公益培训全国行正在进行中" />
        <div class="box_wrap">
          <div class="box">
            <div>
              <div class="text">
                <div class="num">3万+</div>
                <div class="hint">标签审核经验</div>
              </div>
              <div class="text dis">
                <div class="num">180项</div>
                <div class="hint">专业审核</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$yellow: rgb(242, 200, 17);
.page_img3 {
  background: rgb(248, 248, 254);

  .img {
    img {
      min-width: 100%;
      width: 100%;
    }
  }

  .map_list {
    width: 100%;
    font-size: 0;
    position: relative;

    img {
      width: 100%;
    }

    .box_title {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 12%;

      .title {
        font-size: 36px;
      }

      .hint {
        margin: auto;
        font-size: 0.2rem;
        width: 4.45rem;
      }
    }

    .box_wrap {
      position: absolute;
      top: 41%;
      width: 100%;
    }

    .box {
      width: 65%;
      margin: auto;
      display: inline-block;
      vertical-align: middle;
      display: -webkit-box;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;

      .text {
        position: relative;

        .num {
          font-size: 0.6rem;
          color: $yellow;
        }

        .hint {
          font-size: 0.2rem;
        }
      }

      .num:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 1px;
        background: rgb(224, 224, 232);
      }

      .dis {
        margin-top: 0.73rem;
      }
    }
  }

  @media (max-width: 768px) {
    .min_page {
      // padding-top: 90px;
      // padding-bottom: 10px;

      img {
        margin-left: 40px;
        height: auto;
      }

      .box_title {
        // top: 0;

        .title {
          font-size: 25px;
        }

        .hint {
          width: auto;
          font-size: 13px;
        }
      }

      .box_wrap {
        .text {
          .num {
            font-size: 25px;
          }

          .hint {
            font-size: 18px;
          }
        }

        .dis {
          margin-top: 50%;
        }
      }
    }
  }
}
</style>