<template>
  <div class="logo-wrap">
    <!-- <div class="logo dib" @click="$emit('btn','logo')"> -->
    <div class="logo dib" @click="() => this.$router.replace('/')">
      <span class="iconfont iconlogo"></span>
    </div>
    <h1 class="dib logo_text">食品标签在线服务领导品牌</h1>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
$yellow: rgb(242, 200, 17);
.logo-wrap {
  .logo_text {
    font-size: 21px;
    padding-left: 20px;
    color: $yellow;
    font-weight: bold;
    vertical-align: top;
  }

  .logo {
    line-height: 68px;
    cursor: pointer;
    .iconfont {
      font-size: 35px;
      color: $yellow;
    }
  }

}
</style>