<template>
  <div class="page_img2">
    <div class="hidden-xs-only">
      <div class="title">
        <h3>一张标签&nbsp;&nbsp;不可小觑</h3>
        <div class="hint">食品标签的不规范，给企业的发展带来诸多困扰</div>
      </div>
      <div class="info">
        <el-row>
          <el-col :offset="2" :span="8">
            <div class="left_info">
              <div class="box">
                <div class="left_title">食品标签安全重要性</div>
                <div
                  class="content"
                >以避免食品包装上的文字、图案内容均属于标签，标签内容应客观、真实。实践中，食品标签往往未能被食品企业重视，标签内容不真实，导致产品标签与法律或者行业规范的规定不符，既会误导消费者，食品生产者也要承担被食品药品监管部门惩处的代价。</div>
              </div>
            </div>
          </el-col>
          <el-col :offset="2" :span="10">
            <div class="right_info">
              <div class="box_img">
                <div class="img">
                  <img src="https://img.shihaishibiao.com/sh/page2.jpg?imageslim" alt="食品标签安全重要性" />
                </div>
                <div class="icon icon1">
                  <span class="text dib">
                    食品企业
                    <p>面临困扰</p>
                  </span>
                  <span class="iconfont iconicon-test2 dib"></span>
                </div>
                <div class="icon icon2">
                  <span class="iconfont iconicon-test3 dib"></span>
                  <span class="text dib">
                    法规太多
                    <p>难以把控</p>
                  </span>
                </div>
                <div class="icon icon3">
                  <div class="iconfont iconicon-test"></div>
                  <div class="text more">
                    企业规模有限
                    <p>难以支撑专人专岗</p>
                  </div>
                </div>
                <div class="icon icon4">
                  <span class="iconfont iconicon-test1 dib"></span>
                  <span class="text dib more">
                    违规带来
                    <p>高昂的风险成本</p>
                  </span>
                </div>
                <div class="icon icon5">
                  <span class="iconfont iconicon-test4 dib"></span>
                  <span class="text dib more">
                    包材制作量大
                    <p>改动=巨大浪费</p>
                  </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="hidden-sm-and-up min_page">
      <div class="min_title">
        <h3 class="title">一张标签&nbsp;&nbsp;不可小觑</h3>
        <div class="hint">食品标签的不规范，给企业的发展带来诸多困扰</div>
      </div>
      <div class="info">
        <el-row>
          <el-col :span="24">
            <div class="right_info">
              <div class="box_img">
                <div class="img">
                  <img src="https://img.shihaishibiao.com/sh/page2.jpg?imageslim" alt="食品标签的不规范" />
                </div>
                <div class="icon icon1">
                  <span class="text dib">
                    食品企业
                    <p>面临困扰</p>
                  </span>
                  <span class="iconfont iconicon-test2 dib"></span>
                </div>
                <div class="icon icon2">
                  <span class="iconfont iconicon-test3 dib"></span>
                  <span class="text dib">
                    法规太多
                    <p>难以把控</p>
                  </span>
                </div>
                <div class="icon icon3">
                  <div class="iconfont iconicon-test"></div>
                  <div class="text more">
                    企业规模有限
                    <p>难以支撑专人专岗</p>
                  </div>
                </div>
                <div class="icon icon4">
                  <span class="iconfont iconicon-test1 dib"></span>
                  <span class="text dib more">
                    违规带来高昂
                    <p>的风险成本</p>
                  </span>
                </div>
                <div class="icon icon5">
                  <span class="iconfont iconicon-test4 dib"></span>
                  <span class="text dib more">
                    包材制作量大
                    <p>改动=巨大浪费</p>
                  </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$yellow: rgb(242, 200, 17);
.page_img2 {
  background: #ffffff;

  .title {
    padding-top: 100px;
    font-size: 36px;
    text-align: center;

    .hint {
      font-size: 20px;
    }

    h1 {
      font-size: 36px;
      line-height: 80px;
    }
  }

  .info {
    width: 100%;

    .left_info {
      margin-top: 117px;

      .box {
        width: 70%;
        border: 1px solid rgb(223, 225, 231);
        margin: auto;

        .left_title {
          text-align: center;
          width: 100%;
          background: rgb(244, 244, 249);
          line-height: 69px;
          font-size: 20px;
          border-bottom: 1px solid rgb(223, 225, 231);
        }

        .content {
          font-size: 0.16rem;
          padding: 33px;
          text-align: left;
          background: #ffffff;
        }
      }
    }

    .right_info {
      margin-top: 22px;
      padding: 120px 0;
      padding-right: 200px;

      .box_img {
        position: relative;
        margin: auto;
        width: 351px;

        .img {
          width: 100%;

          img {
            width: 100%;
          }
        }

        .icon1 {
          top: -110px;
          left: -83px;
        }

        .icon2 {
          top: -65px;
          right: -135px;
        }

        .icon3 {
          left: -150px;
          top: 129px;
          text-align: right;

          .iconfont {
            margin: auto;
          }
        }

        .icon4 {
          bottom: 130px;
          right: -230px;

          .iconfont {
            margin: auto;
          }
        }

        .icon5 {
          bottom: -76px;
          left: -19px;

          .iconfont {
            margin: auto;
          }
        }

        .icon {
          position: absolute;

          .text {
            width: 88px;
            font-size: 0.19rem;
            font-weight: bold;
            margin-right: 11px;
          }

          .more {
            width: 155px;
          }

          .iconfont {
            font-size: 64px;
            line-height: 106px;
            width: 106px;
            border-radius: 50%;
            background: $yellow;
            text-align: center;
            box-shadow: 0 4px 6px rgb(233, 233, 234);
          }
        }
      }
    }
  }

  .min_page {
    .min_title {
      text-align: center;
      padding-top: 5px;

      .title {
        font-size: 25px;
        padding: 0;
      }

      .hint {
        font-size: 13px;
      }
    }

    .left_info {
      margin: 0 15px;

      .hint {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .info {
        font-size: 12px;
        text-indent: 2em;
        margin-bottom: 10px;
      }
    }

    .right_info {
      padding: 0;
      margin: 0;

      .box_img {
        width: 66%;
        padding: 35px 0;
        margin-top: 15px;

        .icon {
          .text {
            font-size: 13px;
            margin-right: 0;
            width: auto;
          }

          .iconfont {
            line-height: 55px;
            width: 55px;
            font-size: 30px;
          }
        }

        .icon1 {
          left: -20%;
          top: 0;
        }

        .icon2 {
          right: -20%;
          top: 15%;
        }

        .icon3 {
          left: -20%;
          top: 35%;
        }

        .icon4 {
          right: -20%;
          bottom: 15%;
        }

        .icon5 {
          left: -11%;
          bottom: 5%;
        }
      }
    }
  }
}
</style>