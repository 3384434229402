<template>
  <div>
    <swiper class="swipe-main" :options="swiperOption" ref="mySwiper">
      <swiper-slide
        v-for="(item, i) in swiperList"
        :key="i"
        class="swiper-slide"
        :style="`background-image: url(${item.url});`"
      >
        <div class="box" v-if="i % swiperList.length == 0">
          <div>
            <h1 class="title">士海食标</h1>
            <h1 class="title">专注于食品标签审核</h1>
            <div class="hint">
              <p>丰富的行业经验  有效的指导 专业的建议</p>
              <p>帮助企业规避标签错误风险</p>
            </div>
            <div>
              <el-button
                :data-id="i"
                type="danger"
                style="background-color: #fd2305; color: #fff"
                >注册即享首单
                <span class="text-price">{{
                  firstOrderPrice.current.firstOrderPrice
                }}</span>
                元
              </el-button>
              <el-button
                type="danger"
                icon="el-icon-video-play"
                plain
                v-if="videoUrl"
                @click.stop="onPlay"
                >视频介绍</el-button
              >
            </div>
          </div>
        </div>
        <div class="box" v-else-if="i == 1">
          <div>
            <h1 class="title">海量资料免费查</h1>
            <div class="hint">
              <p>汇集大量与食品标签相关的资料与数据</p>
              <p>食品企业的必备工具</p>
            </div>
            <div>
              <el-button
                type="danger"
                style="margin-top: 10px; background-color: #fd2305; color: #fff"
                @click="$emit('login')"
                >注册即享首单
                <span class="text-price">{{
                  firstOrderPrice.current.firstOrderPrice
                }}</span>
                元
              </el-button>
              <el-button
                type="danger"
                icon="el-icon-video-play"
                plain
                v-if="videoUrl"
                @click.stop="onPlay"
                >视频介绍</el-button
              >
            </div>
          </div>
        </div>
        <div class="box" v-else>
          <div>
            <h1 class="title">定制化培训</h1>
            <div class="hint">
              <p>量身定制培训课程</p>
              <p>培训效果更有保障</p>
            </div>
            <div>
              <router-link to="/train">
                <el-button
                  type="danger"
                  style="
                    margin-top: 10px;
                    background-color: #fd2305;
                    color: #fff;
                  "
                >
                  了 解 更 多
                </el-button>
              </router-link>
              <el-button
                class="margin-left-xs"
                type="danger"
                icon="el-icon-video-play"
                plain
                v-if="videoUrl"
                @click.stop="onPlay"
                >视频介绍</el-button
              >
            </div>
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
      <div
        class="swiper-button-prev swiper-button-black"
        slot="button-prev"
      ></div>
      <div
        class="swiper-button-next swiper-button-black"
        slot="button-next"
      ></div>
    </swiper>

    <div class="foot-wrap">
      <div class="foot">
        <div class="item" v-for="(item, index) in footList" :key="index">
          <div class="title">
            {{ item.title }}
            <span v-if="!!item.moreUrl" class="text-md">
              <a
                href="https://www.shihaishibiao.com/docs/price.html#%E4%BC%98%E6%83%A0%E6%94%BF%E7%AD%96"
                target="_blank"
              >
                <i
                  style="font-size: 18px; vertical-align: top"
                  class="el-icon-info"
                />
              </a>
            </span>
          </div>
          <div class="flex align-center">
            <div class="info">
              <span class="number" :class="!index ? 'text-price' : ''">
                {{ item.number }}
              </span>
              <span> {{ item.text }} </span>
            </div>
            <div
              class="
                price-wrap
                shadow-blur
                bg-gradual-orange
                text-white
                margin-left-m
                text-center
              "
              v-if="!index && endDate.length"
            >
              <div class="text-xs">距活动结束</div>

              <div class="text-lg margin-top-m" v-if="endDate.length < 5">
                <span class="text-xl text-bold"> {{ endDate }}</span>
                <span class="text-xs">天</span>
              </div>
              <div class="text-lg margin-top-m" v-else>
                <span> {{ endDate }}</span>
              </div>
            </div>
            <div class="price-wrap shadow-blur" v-if="index === 1">
              <span>折后:</span>
              <span class="text-price price">150</span>
              <div class="text-gray">
                <span>原价:</span>
                <span class="line text-price">300</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title
      :visible.sync="dialogPlay"
      @close="closeDialog"
      :lock-scroll="false"
    >
      <video
        :src="videoUrl"
        controls
        ref="vueMiniPlayer"
        autoplay
        width="100%"
      />
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    firstOrderPrice() {
      return this.$store.getters.priceFirst;
    }
  },
  data() {
    return {
      dialogPlay: false,
      videoUrl: '',
      // videoUrl: "http://47.105.206.28/videos/wangkun.mp4",
      footList: [
        {
          title: '企业首张标签，审核仅需',
          number: '9.9',
          text: '元',
          moreUrl:
            'https://www.shihaishibiao.com/docs/price.html#%E4%BC%98%E6%83%A0%E6%94%BF%E7%AD%96'
        },
        {
          title: '全场活动，下单即享',
          number: '5',
          text: '折'
        },
        {
          title: '量大无忧，充值赠送高达',
          number: '20%',
          text: '代金额'
        }
      ],
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'my-bullet'
        },
        paginationClickable: true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false
        },
        speed: 1000,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          click: function(e) {
            console.log(e.target.dataset.id, this.realIndex);
          }
        }
      },
      swiperList: [
        {
          id: '001',
          url: require('./image/1.jpg')
        },
        {
          id: '002',
          url: require('./image/2.jpg')
        },
        {
          id: '003',
          url: require('./image/3.jpg')
        }
      ],
      endDate: ''
    };
  },
  created() {
    this.footList[0].number = this.firstOrderPrice.current.firstOrderPrice;
    if (!this.firstOrderPrice.next) return;
    const firstTime = this.firstOrderPrice.next.from;
    const day = moment(firstTime).diff(moment(), 'day');
    if (day > 1) this.endDate = String(day);
    else {
      const handleDate = () => {
        const diffTime = moment.duration(moment(firstTime) - moment());
        const hours = String(diffTime.hours()).padStart(2, '0');
        const minutes = String(diffTime.minutes()).padStart(2, '0'); //分钟
        const seconds = String(diffTime.seconds()).padStart(2, '0'); //秒
        if (seconds < 0) return (this.endDate = '');
        this.endDate = `${hours}:${minutes}:${seconds}`;
      };
      handleDate();
      let time = setInterval(handleDate, 1000);
      this.$once('hook:beforeDestroy', () => {
        clearInterval(time);
        time = null;
      });
    }
  },
  methods: {
    closeDialog() {
      this.dialogPlay = false;
      this.$refs.vueMiniPlayer && this.$refs.vueMiniPlayer.pause();
    },
    onPlay() {
      this.dialogPlay = true;
      this.$refs.vueMiniPlayer && this.$refs.vueMiniPlayer.play();
    },
    onRegistry() {
      console.log('click');
      this.$emit('login');
    }
  }
};
</script>
<style lang="scss" scoped>
$width: 100%;

.swiper-slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.box {
  position: relative;
  height: 100%;
  color: #000;
  display: flex;
  align-items: center;
  // margin: 0 3% ;
  margin: auto;
  width: 1200px;
  // padding: 0 20px;
  .title {
    font-weight: bold;
    font-size: 45px;
    margin-bottom: 8px;
    line-height: 1.3;
  }
  .hint {
    font-size: 20px;
    margin-bottom: 40px;
    margin-top: 15px;
    line-height: 1.6;
  }
}
.foot-wrap {
  background: #f6faff;
  color: #000;
  .price-wrap {
    line-height: 1.1;
    color: #ef8304;
    padding: 5px 10px;
    border-radius: 5px;
    .line {
      position: relative;
    }
    .line::after {
      content: '';
      position: absolute;
      height: 1px;
      left: -1px;
      right: -1px;
      background-color: var(--gray);
      top: 50%;
    }
  }
}
.foot {
  display: flex;
  align-items: center;
  height: 120px;
  justify-content: space-between;
  margin: auto;
  width: 1200px;

  // padding: 0 20px;
  .item {
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .number {
      font-size: 45px;
      font-weight: bold;
      padding-bottom: 7px;
      margin-right: 4px;
      font-style: italic;
    }
    .info {
      font-size: 26px;
      display: flex;
      align-items: center;
      color: #ef8304;
    }
  }
}

// 全局穿透央视
.swipe-main {
  height: 60vh;
  max-height: 600px;
}
::v-deep {
  .my-bullet.swiper-pagination-bullet-active {
    width: 32px;
    background: #fff;
    border-radius: 4px;
    opacity: 1;
  }
  .my-bullet {
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 4px;
    opacity: 0.6;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
  }

  .swipe-main:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: inline;
    }
  }
  .swiper-img {
    width: 100%;
  }
  .swiper-button-prev {
    left: 5%;
    display: none;
  }
  .swiper-button-next {
    right: 5%;
    display: none;
  }

  .swiper-container {
    --swiper-navigation-size: 30px;
    // --swiper-theme-color: #fff;
    // --swiper-pagination-color: #00ff33; /* 两种都可以 */
  }
}
</style>
