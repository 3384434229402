<template>
    <div class="page_img6">
        <div class="page_info">
            <div class="code_img">
                <div class="img">
                    <img src="https://img.shihaishibiao.com/sh/qrcode4mp.jpg?imageslim" alt="关注士海食标" />
                </div>
                <div class="code_hint">关注士海食标</div>
                <div class="code_hint">客服热线：{{systemInfo.tel || '400-008-2003' }}</div>
            </div>
            <div class="floor_info">
                <div class="text">
                    <ul class>
                        <li>
                            © {{systemInfo.fromYear || '2019'}}~{{systemInfo.thisYear || ''}} {{systemInfo.copyright || '士海食标shihaishibiao.com 版权所有'}}&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://beian.miit.gov.cn/" target="_blank" style="color:inherit"
                                >{{systemInfo.ICP || '粤ICP备19026232号-1' }}</a
                            >
                        </li>
                        <li>
                            <a
                                style="color:#f2c811"
                                href="https://www.shihaishibiao.com/docs/contactus.html"
                                target="_blank"
                                >{{systemInfo.company || '士海食标（广州）技术服务有限公司'}}</a
                            >
                            （{{systemInfo.address || '广州市天河区元岗路310号'}}）
                        </li>

                        <li>
                            <a
                                class="dib"
                                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602006388"
                                target="_blank"
                            >
                                <li>
                                    <span class="img">
                                        <img src="/static/img/ghs.png" alt="士海食标备案" />
                                    </span>
                                    {{systemInfo.police || '粤公网安备 44010602006388 号'}}
                                </li>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      systemInfo: this.$store.getters.systemInfo 
    }
  },
};
</script>
<style lang="scss" scoped>
.page_img6 {
    width: 100%;
    background: rgb(18, 18, 18);

    .page_info {
        // width: 1350px;
        margin: auto;
        padding: 80px 0;

        .code_img {
            margin: auto;
            // width: 106px;
            text-align: center;

            .img {
                border: 3px solid rgb(255, 174, 0);
                width: 100px;
                font-size: 0;
                line-height: 0;
                text-align: center;
                margin: auto;
                margin-bottom: 10px;
            }

            img {
                width: 100%;
            }

            .code_hint {
                width: 100%;
                font-size: 14px;
                line-height: 1.5;
                color: #ffffff;
                text-align: center;
            }
        }

        .floor_info {
            .text {
                font-size: 14px;
                color: #ffffff;

                li {
                    line-height: 2;
                    padding-right: 10px;
                    color: #999;
                    font-size: 0.12rem;
                    text-align: center;

                    .img {
                        font-size: 0;
                        line-height: 0;
                        vertical-align: text-bottom;
                        margin-right: 3px;

                        img {
                            width: 20px;
                        }
                    }
                }
            }

            .floor_img {
                padding-top: 20px;

                a {
                    margin-right: 6px;

                    img {
                        height: 32px;
                    }
                }
            }
        }
    }
}
</style>
